.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .col {
        width: 100%;
        &--25 {
            width: 25%;
        }
        &--33 {
            width: 33.333333333%;
        }
        &--50 {
            width: 50%;
        }
        &--auto {
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            min-height: 0;
            min-width: 0;
        }
    }
}

.align-c {
    text-align: center;
}

.align-l {
    text-align: left;
}

.align-r {
    text-align: right;
}

.color-w {
    color: #ffffff;
}

.bg-1 {
    background-color: #595B5E;
}

.bg-2 {
    background-color: #283141;
}

.bg-3 {
    background-color: #B5B5B2;
}

.bg-4 {
    background-color: #000000;
}

.bg-5 {
    background-color: #eeeeee;
}

/* Highlight numbers */

.hl-number {
    font-family: 'Lato', sans-serif;
    font-size: 4rem;
    margin-bottom: 1rem;
}

.container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
}

.container-m {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
}

.flex {
    display: flex;
}

.flex-items-center {
    align-items: center;
}

.fill-h {
    width: 100%;
}

.fill-v {
    height: 100%;
}
html,
body {
    background-color: #000;
    @media only screen and (max-width: 600px) {
        overflow-x: hidden;
    }
}

#homepage {
    position: relative;
    .section {
        position: relative;
        height: 150vh;
        overflow: hidden;
        &__image {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            overflow: hidden;
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.7s ease-in;
            &.visible {
                opacity: 1;
                pointer-events: initial;
            }
            &.fit-to-top {
                position: absolute;
                bottom: auto;
                top: 0;
            }
            &.fit-to-bottom {
                position: absolute;
                bottom: 0;
                top: auto;
                opacity: 1;
            }
            &.fixed-bottom {
                position: fixed;
                bottom: 0;
                top: auto;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
                vertical-align: top;
                @media only screen and (max-width: 600px) {
                    width: auto;
                    height: 100%;
                }
            }
            &--half {
                img {
                    left: 0;
                    width: 50%;
                    height: auto;
                    transform: translateY(-50%);
                }
            }
            &--left {
                img {
                    left: 0;
                    right: auto;
                }
            }
            &--right {
                img {
                    left: auto;
                    right: 0;
                }
            }
        }
        &__block {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 4;
            color: #fff;
            @media only screen and (max-width: 600px) {
                padding: 0 1rem;
                box-sizing: border-box;
            }
        }
        header {
            text-align: center;
            width: 100%;
        }
        h2 {
            // font-family: 'Roboto Condensed', sans-serif;
            font-family: 'Tinos', serif;
            font-size: 3.5rem;
            font-weight: 400;
            margin: 0;
            text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
            @media only screen and (max-width: 600px) {
                font-size: 2.5rem;
            }
        }
        p {
            font-size: 1.35rem;
            line-height: 1.35;
            text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
            .hl {
                color: #feb24c;
                font-weight: 500;
            }
            @media only screen and (max-width: 600px) {
                font-size: 1.15rem;
            }
        }
        hr {
            width: 120px;
            margin: 2rem auto;
            border-color: #feb24c;
            border-width: 0.5px;
            &.ml0 {
                margin-left: 0;
            }
        }
        a {
            color: inherit;
            text-decoration: none;
            font-weight: 500;
            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }
        .source {
            font-size: 13px !important;
            margin-top: 40px;
            float: right;
            a {
                font-weight: 700 !important;
            }
        }
    }
    /* Section 1 */
    #section1 {
        color: #fff;
        .section__block {
            top: 15%;
            left: 4%;
            width: 460px;
            @media only screen and (max-width: 600px) {
                top: 25%;
                width: 100%;
                left: 0;
            }
        }
        .link-scroll {
            position: absolute;
            top: calc(100vh - 60px);
            left: 50%;
            transform: translateX(-50%);
            z-index: 4;
            color: #feb24c;
            font-size: 40px;
        }
        .logo-op {
            position: absolute;
            top: 5%;
            left: 50%;
            width: 160px;
            transform: translateX(-50%);
            z-index: 2;
            img {
                width: 100%;
            }
            @media only screen and (max-width: 600px) {
                top: 12%;
                width: 120px;
            }
        }
        h1 {
            font-family: 'Tinos', serif;
            font-size: 4.5rem;
            font-weight: 400;
            line-height: 1.15;
            margin: 0 0 1rem 0;
            text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
            @media only screen and (max-width: 600px) {
                font-size: 2.5rem;
            }
        }
        h3 {
            // font-family: 'Tinos', serif;
            font-size: 1.35em;
            font-weight: 400;
            margin: 0;
            margin-bottom: 2rem;
            text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
            @media only screen and (max-width: 600px) {
                font-size: 1rem;
            }
        }
        .doc-play {
            cursor: pointer;
            &__icon {
                display: inline-block;
                background-color: transparent;
                color: #feb24c;
                border: solid 2px #feb24c;
                border-radius: 100%;
                width: 48px;
                text-align: center;
                @media only screen and (max-width: 600px) {
                    width: 32px;
                }
                i {
                    font-size: 32px;
                    padding: 8px 0 8px 6px;
                    @media only screen and (max-width: 600px) {
                        font-size: 24px;
                        padding: 4px 0 3px 4px;
                    }
                }
            }
            &__label {
                display: inline-block;
                vertical-align: top;
                font-size: 1.20rem;
                line-height: 48px;
                margin-left: 6px;
                text-transform: uppercase;
                color: #feb24c;
                @media only screen and (max-width: 600px) {
                    font-size: 1rem;
                    line-height: 32px;
                    margin-left: 3px;
                }
            }
        }
    }
    /* Section 2 */
    #section2 {
        height: 200vh;
        .section__block {
            top: 50vh;
        }
        p {
            padding: 0 0.5rem;
            &::first-letter {
                font-family: 'Tinos', serif;
                height: auto;
                float: left;
                font-size: 7rem;
                line-height: 5.5rem;
                margin-right: 2rem;
                margin-top: 0.75rem;
                margin-left: 0;
                color: #feb24c;
            }
        }
    }
    /* Section 3 */
    #section3 {}
    /* Section x */
    #section4 {
        height: 300vh !important;
        color: #fff;
        .section__image {
            overflow: initial;
            height: auto;
            width: 100%;
            img {
                position: relative;
                top: 0;
                left: 0;
                transform: none;
                width: 100%;
                @media only screen and (max-width: 600px) {
                    height: 100%;
                    width: auto;
                    transform: translateX(-40%);
                }
            }
            &.fit-to-top {
                top: 60vh;
            }
            &.fixed-bottom {
                top: auto;
            }
        }
        .section__block {
            top: 70vh;
        }
        .block-bottom-left {
            text-align: left;
            top: 75%;
            left: 5%;
            width: 400px;
            @media only screen and (max-width: 600px) {
                width: auto;
            }
        }
    }
    #section5 {
        .block-top-right {
            top: 50%;
            left: auto;
            right: 5%;
            width: 400px;
            text-align: right;
            @media only screen and (max-width: 600px) {
                width: auto;
                right: auto;
                left: 0;
                text-align: left;
            }
        }
    }
    #section6,
    #section7,
    #section8,
    #section9 {
        .section__image {
            img {
                @media only screen and (max-width: 600px) {
                    width: 100%;
                    left: 0;
                    right: auto;
                    top: 40px;
                    transform: none;
                }
            }
        }
        .block {
            color: #fff;
            font-size: 1.5rem;
            line-height: 1.875;
            width: 45%;
            top: 50%;
            text-align: center;
            &--left {
                right: auto;
                left: 7.5%;
            }
            &--right {
                left: auto;
                right: 7.5%;
            }
            @media only screen and (max-width: 600px) {
                width: 100%;
                left: 0;
                right: auto;
            }
            h4 {
                // font-family: 'Roboto Condensed', sans-serif;
                font-family: 'Tinos', serif;
                font-weight: 400;
                font-size: 2rem;
                margin: 0 0 0.25em 0;
                line-height: 1.35;
                @media only screen and (max-width: 600px) {
                    font-size: 1.5rem;
                }
            }
            h6 {
                // font-family: 'Roboto Condensed', sans-serif;
                font-weight: 400;
                font-size: 1.15rem;
                margin: 0 0 0.25em 0;
                line-height: 1.35;
                color: #feb24c;
                @media only screen and (max-width: 600px) {
                    font-size: 1rem;
                }
            }
            p {
                font-size: 1.5rem;
                @media only screen and (max-width: 600px) {
                    font-size: 1.15rem;
                }
            }
        }
        .audio-control {
            z-index: 100;
            position: absolute;
            bottom: 50px;
            right: 50px;
            color: #fff;
            border: solid 2px #fff;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            box-sizing: border-box;
            text-align: center;
            line-height: 20px;
            font-size: 20px;
            padding: 8px 0 8px 0;
            cursor: pointer;
            .fa-volume-up {
                padding-left: 3px;
            }
            .fa-pause {
                display: none;
            }
            &.playing {
                .fa-volume-up {
                    display: none;
                }
                .fa-pause {
                    display: block;
                }
            }
            &--left {
                left: 50px;
                right: auto;
            }
        }
    }
    #section10 {
        height: 200vh;
        .row {
            margin-top: 80px;
        }
        .section__block {
            top: 60vh;
        }
        .counter {
            padding: 0 24px;
            &__value {
                font-size: 4rem;
                margin-bottom: 10px;
                @media only screen and (max-width: 600px) {
                    font-size: 3rem;
                    margin-bottom: 5px;
                }
            }
            &__label {
                font-size: 1.15rem;
                @media only screen and (max-width: 600px) {
                    font-size: 1rem;
                }
            }
        }
        @media only screen and (max-width: 600px) {
            .row {
                .col--33 {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
        }
    }
    #historico-uso {
        height: 550vh;
        color: #fff;
        background: #000000;
        .section__block {
            top: 50vh;
        }
        .block-info {
            left: calc(50% - 550px);
            width: 36%;
            .box {
                margin-top: 50px;
                padding: 1rem;
                color: #fff;
                opacity: 0;
                transition: opacity 500ms ease-out;
                h3 {
                    display: inline-block;
                    color: #FDB159;
                    font-family: 'Tinos', serif;
                    font-size: 2rem;
                    margin: 0;
                    font-weight: 400;
                    margin-bottom: 1.5rem;
                    text-transform: uppercase;
                    @media only screen and (max-width: 600px) {
                        font-size: 1.25rem;
                    }
                }
                p {
                    font-size: 1.20rem;
                    margin: 0;
                    text-shadow: none;
                    @media only screen and (max-width: 600px) {
                        font-size: 0.9rem;
                        line-height: 1.25;
                    }
                }
                @media only screen and (max-width: 600px) {
                    padding: 10px;
                }
            }
            @media only screen and (max-width: 600px) {
                left: 0;
                width: 80%;
            }
        }
        #info-chart1 {
            top: calc(150vh);
        }
        #info-chart2 {
            top: calc(250vh);
        }
        #info-chart3 {
            top: calc(350vh);
        }
        #info-chart4 {
            top: calc(450vh);
        }
        .section__chart {
            position: absolute;
            width: 1100px;
            top: calc(150vh + 50px);
            left: calc(50% - 550px);
            z-index: 3;
            &--fixed {
                position: fixed;
                top: 50px;
            }
            &--to-bottom {
                position: absolute;
                bottom: 50px;
                top: auto;
                height: calc(100vh - 100px);
            }
            .chart {
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100%;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.75s ease-out;
                z-index: 4;
                &.visible {
                    opacity: 1;
                    pointer-events: initial;
                }
                @media only screen and (max-width: 600px) {
                    top: auto;
                    bottom: 0;
                }
            }
            .source {
                position: absolute;
                right: 10px;
                top: calc(100vh - 90px);
                margin-top: 0;
            }
            @media only screen and (max-width: 600px) {
                width: 100%;
                left: 0;
                bottom: 30px;
            }
        }
        .section__chart-bg {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            overflow: hidden;
            z-index: 1;
            pointer-events: none;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                opacity: 0;
                pointer-events: none;
                transform: translate(-50%, -50%);
                vertical-align: top;
                transition: opacity 0.7s ease-in;
                z-index: 2;
                &.visible {
                    opacity: 1; // pointer-events: initial;
                }
                @media only screen and (max-width: 600px) {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
    #buscador {
        height: 200vh;
        color: #fff;
        .section__block {
            top: 60vh;
        }
    }
    #historias {
        height: auto;
        .section__block {
            position: relative;
            height: auto;
            top: 20vh;
            text-align: center;
            padding-bottom: 150px;
            @media only screen and (max-width: 600px) {
                padding-bottom: 100px;
            }
        }
        color: #fff;
        .cards {
            margin-top: 100px;
        }
        .card {
            text-align: initial;
            display: flex;
            margin-bottom: 35px;
            padding-bottom: 35px;
            border-bottom: solid 0.5px #feb24c;
            &:last-child {
                border-bottom: none;
            }
            @media only screen and (max-width: 600px) {
                flex-wrap: wrap;
            }
            &__image {
                width: 40%;
                img {
                    width: 100%;
                }
                @media only screen and (max-width: 600px) {
                    width: 100%;
                }
            }
            &__content {
                margin-left: 5%;
                width: 55%;
                @media only screen and (max-width: 600px) {
                    margin-left: 0;
                    width: 100%;
                }
            }
            &__title {
                margin: 0; // font-family: 'Roboto Condensed', sans-serif;
                font-family: 'Tinos', serif;
                font-size: 2rem;
                font-weight: 400;
                margin-bottom: 1rem;
                @media only screen and (max-width: 600px) {
                    font-size: 1.5rem;
                }
            }
            &__standfirst {
                margin: 0;
                font-size: 1rem;
                @media only screen and (max-width: 600px) {
                    font-size: 0.85rem;
                }
            }
        }
    }
}

/* Billboard */

.bb text {
    fill: #fff;
    font-size: 12px;
}

.bb line,
.bb path {
    stroke: #fff;
}

.bb-line {
    stroke-width: 2px;
    stroke: #e47171;
}

.bb-circle {
    r: 5;
    fill: #e47171;
}

.bb-tooltip-container {
    color: #000000;
}

.doc-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 5;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .close-button {
        cursor: pointer;
        position: absolute;
        z-index: 2;
        bottom: 20px;
        left: 50%;
        margin-left: -50px;
        width: 100px;
        background-color: rgba($color: #424242, $alpha: 0.5);
        color: #fff;
        font-size: 1rem;
        color: #fff;
        text-align: center;
        padding: 0.5rem 0;
        border-radius: 30px;
        &:hover {
            background-color: rgba($color: #424242, $alpha: 0.75);
        }
    }
    &.visible {
        display: block;
    }
}

#section-buscador {
    min-height: 100vh;
    #provincia-sel-clase {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            margin-right: 10px;
            border: solid 1px #000000;
            padding: 0.5rem 1rem;
            cursor: pointer;
        }
    }
}

.button {
    display: inline-block;
    margin-top: 50px;
    background-color: transparent;
    border: solid 2px #fff;
    padding: 1rem 3rem;
    text-transform: uppercase;
    &:hover {
        background-color: rgba($color: #fff, $alpha: 0.15);
    }
}

.link {
    display: inline-block;
    min-width: 120px;
    margin: 20px auto 0 auto;
    background-color: rgba($color: #feb24c, $alpha: 0.85);
    padding: 0.5rem;
    white-space: nowrap;
    &:hover {
        background-color: rgba($color: #feb24c, $alpha: 0.95);
    }
}

.footer {
    text-align: left;
    .row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1024px;
        padding: 50px 0;
        margin: 0 auto;
        border-top: solid 1px #feb24c;
        .col-1 {
            width: 60%;
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
        }
        .col-2 {
            width: 40%;
            @media only screen and (max-width: 600px) {
                width: 100%;
                margin-top: 40px;
            }
        }
    }
    h5 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 30px;
    }
    img {
        display: block;
        width: 100%;
        max-width: 200px;
        margin-bottom: 30px;
    }
    .creditos {
        line-height: 1.5;
        width: 80%;
        @media only screen and (max-width: 600px) {
            width: 100%;
            font-size: 14px;
        }
    }
}
/* Nav */

#site-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 7px 0;
    z-index: 2;
    box-sizing: border-box;
    &.fixed {
        position: fixed;
        box-shadow: 2px 0 3px rgba(0, 0, 0, 0.35);
        background-color: #000000;
    }
    @media only screen and (max-width: 600px) {
        position: absolute;
        z-index: 100;
    }
    .logo-op {
        display: inline-block;
        margin-left: 10px;
        padding: 5px 0;
        img {
            display: block;
            height: 26px;
        }
    }
    .lang {
        float: left;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-left: 20px;
        li {
            display: inline-block;
            margin-right: 1rem;
            color: #feb24c;
            text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.35);
            line-height: 26px;
            font-size: 16px;
            a {
                color: inherit;
                text-decoration: none;
            }
            &.selected {
                a {
                    text-decoration: underline;
                }
            }
        }
        @media only screen and (max-width: 600px) {
            margin-left: 10px;
            li {
                margin-right: 10px;
                font-size: 14px;
            }
        }
    }
    .social {
        float: right;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-right: 20px;
        li {
            display: inline-block;
            margin-left: 1rem;
            text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.35);
            a {
                display: block;
                border: solid 1px #feb24c;
                border-radius: 100%;
                width: 26px;
                font-size: 16px;
                line-height: 24px;
                color: #feb24c;
                text-align: center;
                text-decoration: none;
                box-sizing: border-box;
            }
        }
        @media only screen and (max-width: 600px) {
            margin-right: 10px;
            li {
                margin-left: 10px;
                font-size: 14px;
            }
        }
    }
}
@import url("https://fonts.googleapis.com/css?family=Merriweather:400,400i,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700");
@font-face {
    font-family: 'Tinos';
    src: url("../fonts/Tinos-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: 'Tinos';
    src: url("../fonts/Tinos-Italic.ttf");
    font-style: italic;
}

@font-face {
    font-family: 'Tinos';
    src: url("../fonts/Tinos-Bold.ttf");
    font-weight: 400;
}

@font-face {
    font-family: 'ClearSans';
    src: url("../fonts/ClearSans-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: 'ClearSans';
    src: url("../fonts/ClearSans-Bold.ttf");
    font-weight: 700;
}

html,
body {
    font-family: 'ClearSans', Arial, sans-serif;
    font-size: 100%;
    margin: 0;
}

* {
    -webkit-font-smoothing: antialiased;
}

.vh100 {
    height: 100vh;
}